function info(message) {
    console.log(message);
}

function error(message) {
    console.error(message);
}


export {
    info, 
    error
}